var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"officeForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-overlay',{attrs:{"show":_vm.loading,"variant":_vm.overlayVariant,"opacity":_vm.overlayOpacity,"rounded":"sm"}},[(!_vm.officeNotFound)?_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-form-group',{attrs:{"label":"Office Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Office Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"code","placeholder":"Office Name","data-vv-validate-on":"blur","disabled":_vm.readOnly},model:{value:(_vm.office.name),callback:function ($$v) {_vm.$set(_vm.office, "name", $$v)},expression:"office.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Address","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Address","vid":"address","rules":{ regex: /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,73}((?![\^!@#$*~ <>?]).)$/, required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-google-autocomplete',{ref:"address",class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"map","classname":"form-control","state":errors.length > 0 ? false:null,"name":"address","placeholder":"Address","data-vv-validate-on":"blur","types":"","disabled":_vm.readOnly},on:{"inputChange":_vm.onInputChanged,"placechanged":_vm.getAddressData},model:{value:(_vm.office.address),callback:function ($$v) {_vm.$set(_vm.office, "address", $$v)},expression:"office.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Address Line 2","label-for":"address_2"}},[_c('b-form-input',{attrs:{"id":"address_2","name":"address_2","placeholder":"Suite, unit, building, floor, etc.","data-vv-validate-on":"blur","disabled":_vm.readOnly},model:{value:(_vm.office.address_2),callback:function ($$v) {_vm.$set(_vm.office, "address_2", $$v)},expression:"office.address_2"}})],1),_c('b-form-group',{attrs:{"label":"City","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"City","vid":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"city","state":errors.length > 0 ? false:null,"name":"city","placeholder":"City","data-vv-validate-on":"blur","disabled":_vm.readOnly},model:{value:(_vm.office.city),callback:function ($$v) {_vm.$set(_vm.office, "city", $$v)},expression:"office.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Province / State","label-for":"province"}},[_c('validation-provider',{attrs:{"name":"Province / State","vid":"province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"province","state":errors.length > 0 ? false:null,"name":"province","placeholder":"Province / State","data-vv-validate-on":"blur","disabled":_vm.readOnly},model:{value:(_vm.office.province),callback:function ($$v) {_vm.$set(_vm.office, "province", $$v)},expression:"office.province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Postal / ZIP Code","label-for":"postal_code"}},[_c('validation-provider',{attrs:{"name":"Postal Code","vid":"postal_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"postal_code","state":errors.length > 0 ? false:null,"name":"postal_code","placeholder":"Postal Code","data-vv-validate-on":"blur","disabled":_vm.readOnly},model:{value:(_vm.office.postal_code),callback:function ($$v) {_vm.$set(_vm.office, "postal_code", $$v)},expression:"office.postal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Country","label-for":"country"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"country","state":errors.length > 0 ? false:null,"name":"country","placeholder":"Country","data-vv-validate-on":"blur","disabled":_vm.readOnly},model:{value:(_vm.office.country),callback:function ($$v) {_vm.$set(_vm.office, "country", $$v)},expression:"office.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","vid":"phone","rules":{ regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"phone","options":_vm.cleaveOptions,"state":errors.length > 0 ? false:null,"raw":false,"name":"phone","placeholder":"Phone","data-vv-validate-on":"blur","disabled":_vm.readOnly},model:{value:(_vm.office.phone),callback:function ($$v) {_vm.$set(_vm.office, "phone", $$v)},expression:"office.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email","placeholder":"Email","data-vv-validate-on":"blur","disabled":_vm.readOnly},model:{value:(_vm.office.email),callback:function ($$v) {_vm.$set(_vm.office, "email", $$v)},expression:"office.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('b-alert',{staticClass:"mt-1",attrs:{"variant":"info","show":errors.length > 0}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_c('span',[_vm._v("An office email is required for billing/invoicing")])],1)])]}}],null,true)})],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],staticClass:"mt-1",attrs:{"variant":"danger","show":_vm.serverError !== null}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.serverError)}})],1)]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideSubmit && !_vm.readOnly),expression:"!hideSubmit && !readOnly"}],staticClass:"mt-1",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.loading}},[_c('span',[_vm._v(_vm._s(_vm.$t('buttons.submit')))])])],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }