<template>
  <validation-observer
    ref="officeForm"
    #default="{invalid}"
  >
    <b-overlay
      :show="loading"
      :variant="overlayVariant"
      :opacity="overlayOpacity"
      rounded="sm"
    >
      <b-form
        v-if="!officeNotFound"
        class="auth-login-form mt-2"
        @submit.prevent="submitForm"
      >
        <!-- Office Name -->
        <b-form-group
          label="Office Name"
          label-for="name"
        >
          <validation-provider
            #default="{ errors }"
            name="Office Name"
            vid="name"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="office.name"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              name="code"
              placeholder="Office Name"
              data-vv-validate-on="blur"
              :disabled="readOnly"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Office Address -->
        <b-form-group
          label="Address"
          label-for="address"
        >
          <validation-provider
            #default="{ errors }"
            name="Address"
            vid="address"
            :rules="{ regex: /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,73}((?![\^!@#$*~ <>?]).)$/, required }"
          >
            <vue-google-autocomplete
              id="map"
              ref="address"
              v-model="office.address"
              classname="form-control"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              name="address"
              placeholder="Address"
              data-vv-validate-on="blur"
              types=""
              :disabled="readOnly"
              @inputChange="onInputChanged"
              @placechanged="getAddressData"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Address 2 -->
        <b-form-group
          label="Address Line 2"
          label-for="address_2"
        >
          <b-form-input
            id="address_2"
            v-model="office.address_2"
            name="address_2"
            placeholder="Suite, unit, building, floor, etc."
            data-vv-validate-on="blur"
            :disabled="readOnly"
          />
        </b-form-group>

        <!-- City -->
        <b-form-group
          label="City"
          label-for="city"
        >
          <validation-provider
            #default="{ errors }"
            name="City"
            vid="city"
            rules="required"
          >
            <b-form-input
              id="city"
              v-model="office.city"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              name="city"
              placeholder="City"
              data-vv-validate-on="blur"
              :disabled="readOnly"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Province -->
        <b-form-group
          label="Province / State"
          label-for="province"
        >
          <validation-provider
            #default="{ errors }"
            name="Province / State"
            vid="province"
            rules="required"
          >
            <b-form-input
              id="province"
              v-model="office.province"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              name="province"
              placeholder="Province / State"
              data-vv-validate-on="blur"
              :disabled="readOnly"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Postal Code -->
        <b-form-group
          label="Postal / ZIP Code"
          label-for="postal_code"
        >
          <validation-provider
            #default="{ errors }"
            name="Postal Code"
            vid="postal_code"
            rules="required"
          >
            <b-form-input
              id="postal_code"
              v-model="office.postal_code"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              name="postal_code"
              placeholder="Postal Code"
              data-vv-validate-on="blur"
              :disabled="readOnly"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Country -->
        <b-form-group
          label="Country"
          label-for="country"
        >
          <validation-provider
            #default="{ errors }"
            name="Country"
            vid="country"
            rules="required"
          >
            <b-form-input
              id="country"
              v-model="office.country"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              name="country"
              placeholder="Country"
              data-vv-validate-on="blur"
              :disabled="readOnly"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Phone -->
        <b-form-group
          label="Phone"
          label-for="phone"
        >
          <validation-provider
            #default="{ errors }"
            name="Phone"
            vid="phone"
            :rules="{ regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, required }"
          >
            <cleave
              id="phone"
              v-model="office.phone"
              :options="cleaveOptions"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              :raw="false"
              class="form-control"
              name="phone"
              placeholder="Phone"
              data-vv-validate-on="blur"
              :disabled="readOnly"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Email -->
        <b-form-group
          label="Email"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            vid="email"
            rules="required|email"
          >
            <b-form-input
              id="email"
              v-model="office.email"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              name="email"
              placeholder="Email"
              data-vv-validate-on="blur"
              :disabled="readOnly"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <b-alert
              class="mt-1"
              variant="info"
              :show="errors.length > 0"
            >
              <div
                class="alert-body"
              >
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                <span>An office email is required for billing/invoicing</span>
              </div>
            </b-alert>
          </validation-provider>
        </b-form-group>

        <!-- server alert -->
        <b-alert
          v-height-fade.appear
          class="mt-1"
          variant="danger"
          :show="serverError !== null"
        >
          <div
            class="alert-body"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            <span v-html="serverError" />
          </div>
        </b-alert>

        <!-- submit buttons -->
        <b-button
          v-show="!hideSubmit && !readOnly"
          class="mt-1"
          type="submit"
          variant="primary"
          block
          :disabled="invalid || loading"
        >
          <span>{{ $t('buttons.submit') }}</span>
        </b-button>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BAlert,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  heightFade,
} from '@core/directives/animations'
import {
  required,
  regex,
} from '@validations'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BAlert,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    VueGoogleAutocomplete,
    Cleave,
  },
  directives: {
    'height-fade': heightFade,
  },
  props: {
    hideSubmit: {
      type: Boolean,
      default: false,
    },
    officeId: {
      type: Number,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      office: {},
      required,
      regex,
      serverError: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      noErrors: false,
      cleaveOptions: {
        phone: true,
        phoneRegionCode: 'US',
        delimiter: '-',
      },
      officeNotFound: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
  async created() {
    try {
      if (this.mode === 'edit' || this.readOnly) {
        await this.getOffice(this.officeId).then(res => {
          this.office = res.data
        })
      }
    } catch (e) {
      console.debug(`Error in created() of AddOfficeForm.vue: ${e.message}`)
    }
  },
  methods: {
    ...mapActions('office', ['createOffice', 'getOffice', 'updateOffice']),
    getAddressData(addressData) {
      try {
        const {
          subpremise, street_number, route, administrative_area_level_1, locality, postal_code, country,
        } = addressData
        // eslint-disable-next-line camelcase
        const formatted = street_number ? `${street_number} ${route}` : route
        this.updateAddress(formatted)
        this.$set(this.office, 'address_2', subpremise)
        this.$set(this.office, 'province', administrative_area_level_1)
        this.$set(this.office, 'city', locality)
        this.$set(this.office, 'postal_code', postal_code)
        this.$set(this.office, 'country', country)
      } catch (e) {
        console.debug('Could not fetch line 2 address from Google Autocomplete:')
      }
    },
    onInputChanged({ newVal }) {
      this.updateAddress(newVal)
      if (newVal === '') {
        this.$set(this.office, 'address', null)
      }
    },
    updateAddress(address) {
      this.$set(this.office, 'address', address)
      this.$refs.address.$el.value = address
    },
    submitForm() {
      return new Promise(resolve => {
        this.$refs.officeForm.validate().then(async success => {
          if (success) {
            this.serverError = null
            this.loading = true
            try {
              if (this.activeUser.currentRole.name !== 'admin') {
                this.office.users = []
                this.office.users.push(this.activeUser.id)
              }
              if (this.mode === 'edit') {
                const {
                  subscription, billing_customer, active_subscription, user_count, formatted_address, user_limit, created_at, updated_at, ...payload
                } = this.office
                await this.updateOffice(payload)
              } else {
                await this.createOffice(this.office)
              }
              this.noErrors = true
              this.$emit('success', { message: 'Successfully added your office details to DenTyme' })
              this.resetForm()
            } catch (e) {
              console.debug(`Error in submitForm in AddOfficeForm: ${e.message}`)
              this.$emit('error', { message: 'Could not add your office details to DenTyme' })
            } finally {
              this.loading = false
            }
          }
        }).finally(() => resolve(this.noErrors))
      })
    },
    resetForm() {
      this.office = {}
      this.$refs.officeForm.reset()
    },
  },
}
</script>

<style lang="scss">
  .pac-container { z-index: 10000 !important; }
</style>
