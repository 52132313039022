<template>
  <b-modal
    id="add-office-modal"
    :title="$t('offices.add_office')"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    @ok="handleOk"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <add-office-form
            ref="addOfficeForm"
            :hide-submit="true"
            @success="handleSuccess"
            @error="handleError"
          />
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BModal, BContainer, BRow, BCol,
} from 'bootstrap-vue'
import {
  heightFade,
} from '@core/directives/animations'
import AddOfficeForm from '@/views/components/forms/AddOfficeForm.vue'

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    AddOfficeForm,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.$refs.addOfficeForm.submitForm()
    },
    handleSuccess(evt) {
      this.$emit('success', evt)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('add-office-modal')
      })
    },
    handleError(evt) {
      this.$emit('error', evt)
    },
  },
}
</script>

<style lang="scss">
  .is-invalid {
    .vs__dropdown-toggle {
      border-color: #ea5455 !important;
    }
  }
</style>
