<template>
  <b-modal
    v-if="selectedOffice"
    id="view-office-modal"
    :title="$t('page_titles.view_office')"
    ok-only
  >
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <add-office-form
            ref="viewOfficeForm"
            :hide-submit="true"
            :read-only="true"
            :office-id="selectedOffice.id"
          />
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BModal, BContainer, BRow, BCol,
} from 'bootstrap-vue'
import {
  heightFade,
} from '@core/directives/animations'
import AddOfficeForm from '@/views/components/forms/AddOfficeForm.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    AddOfficeForm,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('office', ['selectedOffice']),
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.$refs.addOfficeForm.submitForm()
    },
    handleSuccess(evt) {
      this.$emit('success', evt)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('view-office-modal')
      })
    },
    handleError(evt) {
      this.$emit('error', evt)
    },
  },
}
</script>

<style lang="scss">
  .is-invalid {
    .vs__dropdown-toggle {
      border-color: #ea5455 !important;
    }
  }
</style>
